<template>
  <div class="container">
    <div class="row">
      <div class="offset-10 col-2 mt-5">
        <label></label>
        <b-button
          class="w-100 mt-2"
          variant="outline-primary"
          @click="gotoBackend()"
          >ระบบจัดการ</b-button
        >
      </div>
    </div>
    <div class="row justify-center align-items-center mt-5">
      <div class="col-3">
        <h4 class="text-center mt-10" style="color: #4579df">Log Activity</h4>
<!--          <b-form-group class="mb-0">-->
<!--              <label>ตัวกรอง</label>-->
<!--              <b-form-select-->
<!--                      v-model="date.filter"-->
<!--                      class="form-control"-->
<!--                      id="input1"-->
<!--                      :options="options_filter"-->
<!--              ></b-form-select>-->
<!--          </b-form-group>-->
      </div>
      <div class="col-3">
        <label>วันเริ่ม</label>
        <date-picker
          class="w-100"
          v-model="date.start_event_date"
          input-class="form-control"
          placeholder="ระบุวัน/เดือน/ปีเกิด"
          value-type="date"
          format="DD-MM-YYYY"
          :editable="false"
        ></date-picker>
      </div>
      <div class="col-3">
        <label>วันสิ้นสุด</label>
        <date-picker
          class="w-100"
          v-model="date.end_event_date"
          input-class="form-control"
          placeholder="ระบุวัน/เดือน/ปีเกิด"
          value-type="date"
          format="DD-MM-YYYY"
          :editable="false"
        ></date-picker>
      </div>
      <div class="col-2">
        <label></label>
        <input
          class="form-control mt-2"
          type="text"
          placeholder="ค้นหา"
          v-model="date.event_name"
          v-on:keyup.enter="onEnter"
        />
      </div>
      <div class="col-1 mt-5">
        <label></label>
        <b-button
          class="w-100 mt-2"
          variant="outline-primary"
          @click="showLog()"
          >ค้นหา</b-button
        >
      </div>
    </div>
    <div class="col-12 mt-5">
      <table class="Report table table-striped">
        <thead>
          <tr style="background-color: #192e58; color: #ffffff">
            <th scope="col">Date and Time</th>
            <th scope="col">User Name</th>
            <th scope="col">Status</th>
            <th scope="col">Log Detail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data_log_user" :key="i">
            <td>{{ formatDatetime(item.time) }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.detail }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-15 justify-center align-items-center">
        <div class="col-auto">
          1-{{ date.limit }} of {{ paginateLoguser.total }} items
        </div>
        <div class="col">
          <v-pagination
            @input="showLog"
            v-model="paginateLoguser.currentPage"
            :length="paginateLoguser.lastPage"
          ></v-pagination>
        </div>
        <div class="col-auto">
          <select
            v-model="page"
            @change="showLog(page)"
            class="row-option py-2 px-3"
          >
            <option v-for="i in paginateLoguser.lastPage" :key="i" :value="i">
              {{ i }} / page
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "FormExportData",
  components: {
    DatePicker,
  },
  data() {
    return {
      dateSelected: "event",
      page: 1,
      date: {
        start_event_date: "",
        end_event_date: "",
        event_name: "",
          filter: ''
      },
        options_filter:[
            {text: "ทั้งหมด", value: ""},
            {text: "ไม่เข้าสู่ระบบเกิน 6 เดือน", value: "เกิน 6 เดือน"},
            {text: "รออนุมัติ", value: "รออนุมัติ"},
        ],
      moment: moment.locale("th"),
    };
  },
  methods: {
    showLog(page = 1) {
      this.showLoading();
      this.date["limit"] = 10;
      this.date["page"] = page;
      // this.date['page'] = this.paginateFormList.currentPage;
      this.date["start_event_date"] = this.formatDate(
        this.date.start_event_date
      );
      this.date["end_event_date"] = this.formatDate(this.date.end_event_date);
      this.$store.dispatch("datauser/getDatalog", this.date);
      this.hideLoading();
    },
    gotoBackend() {
      let url = "http://osccadmin.kisra.co.th/bypass";
      window.open(url);
    },
    formatDatetime(date) {
      return date
        ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
        : "";
    },
    formatDate(date) {
      return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
    },
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
  },
  created() {
    this.showLog();
  },
  computed: {
    ...mapState({
      data_log_user: (state) => {
        return state.datauser.data_log_user;
      },
      paginateLoguser: (state) => {
        return state.datauser.paginateLoguser;
      },
    }),
  },
};
</script>

<style scoped>
table th:first-child {
  border-radius: 10px 0 0 0;
}

table th:last-child {
  border-radius: 0 10px 0 0;
}
</style>
